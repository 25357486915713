import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {PlusCircle, InfoCircle, Pencil, Trash} from "react-bootstrap-icons";

import {useAlert}    from "react-alert";
import {useNavigate} from "react-router-dom";

import SortableTable from "components/shared/SortableTable";
import YesNoModal    from "components/shared/YesNoModal";

import EditInstance from "./EditInstance";

const InstancesList = () => {
	const alert                       = useAlert();
	const redirect                    = useNavigate();
	const [instances, setInstances]   = useState([]);
	const [showEdit, setShowEdit]     = useState(false);
	const [instance, setInstance]     = useState(null);
	const [showDelete, setShowDelete] = useState(false);

	const headers = useMemo(() => [
		{
			label  : "Id",
			member : "id",
			width  : "90px"
		},
		{
			label  : "Bezeichnung",
			member : "name"
		},
		{
			label      : "Benutzer",
			member     : null,
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						{member.user.firstname} {member.user.lastname}
						<br />
						<a href={`mailto:${member.user.email}`}>{member.user.email}</a>
					</>
				);
			}
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			width      : "15%",
			functional : ({member}) => {
				return (
					<>
						<button className="btn"><InfoCircle style={{opacity : 1}} /></button>
						<button className="btn" onClick={() => {setInstance(member); setShowEdit(true);}}><Pencil /></button>
						<button className="btn" onClick={() => {setInstance(member); setShowDelete(true);}}><Trash /></button>
					</>
				);
			}
		}
	], []);

	const load = useCallback(() => {
		axios.get("/tr-instances").then(response => {
			setInstances(response.data);
		}).catch(error => alert.error(`Fehler beim laden der Instanzen: ${error}`));
	}, [alert]);

	useEffect(() => {
		load();
	}, [load]);

	const deleteInstance = () => {
		axios.delete(`/tr-instances/${instance?.id}`).then(() => {
			alert.success(`Die Instanz '${instance.name}' wurde erfolgreich gelöscht.`);
			setInstance(null);
			load();
		})
	};

	return (
		<>
			<h2 className="py-4">
				Berichtsheft Instanzen
				<button className="btn btn-success btn-sm float-right" onClick={() => {
					setInstance(null);
					setShowEdit(true);
				}}> <PlusCircle className="big" />neue Instanz</button>
			</h2>
			<SortableTable headers={headers} data={instances} rowCallback={member => redirect(`/TraineeReports/${member.id}`)} />
			<EditInstance show={showEdit} setShow={setShowEdit} instance={instance} callback={() => load()} />
			<YesNoModal title="Instanz löschen" text={`Möchten Sie die Instanz '${instance?.name}' wirklich löschen?`} show={showDelete} setShow={setShowDelete} callback={() => deleteInstance()} />
		</>
	);
};

export default InstancesList;