import React, {useState, useEffect, useMemo, useCallback} from "react";
import axios from "axios";

import {ChevronLeft}        from "react-bootstrap-icons";
import {NavLink, useParams} from "react-router-dom";
import {useAlert}           from "react-alert";
import {DateTime}           from "luxon";

import Spinner from "components/shared/Spinner";

const InstanceSingle = () => {
	const {id}                              = useParams();
	const alert                             = useAlert();
	const [posts, setPosts]                 = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);
	const [post, setPost]                   = useState(null);
	const [currentPost, setCurrentPost]     = useState(null);
	const [instance, setInstance]           = useState(null);
	const [ready, setReady]                 = useState(false);
	const [year, setYear]                   = useState(0);

	const years = useMemo(() => {
		const uniqueYears = [];
		for (const post of posts) {
			const date = DateTime.fromISO(post["published_at"]);
			const year = date.year;
			if (!uniqueYears.includes(year))
				uniqueYears.push(year);
		}
		if (uniqueYears.length > 0)
			setYear(uniqueYears[0]);

		return uniqueYears.sort().reverse();
	}, [posts]);

	useEffect(() => {
		setFilteredPosts(posts.filter(post => {
			return DateTime.fromISO(post["published_at"]).year === year;
		}));
	}, [year, posts]);

	useEffect(() => {
		if (posts.length < 1 || !post || !instance)
			return;

		setReady(false);
		axios.get(`${instance.baseURL}/ghost/api/content/posts/${post.id}?key=${instance.apiKey}`).then(response => {
			setCurrentPost(response.data.posts[0]);
			setReady(true);
		}).catch(error => alert.error(`Fehler beim Laden des Beitrags: ${error}`));
	}, [post, posts, instance, alert]);

	useEffect(() => {
		axios.get(`/tr-instances/${id}`).then(response => {
			const instance = {...response.data};
			setInstance(instance);
			axios.get(`${instance.baseURL}/ghost/api/content/posts?limit=all&fields=id,title,published_at&&order=published_at%20desc&key=${instance.apiKey}`).then(response => {
				const posts = response.data.posts;
				if (posts.length > 0)
					setPost(posts[0]);
				setPosts(posts);
			}).catch(error => alert.error(`Fehler beim Laden der Berichtsheftseinträge: ${error}`));
		}).catch(error => alert.error(`Fehler beim Laden der Instanzdaten: ${error}`));
	}, [alert]);

	return (
		<>
			<h2 className="py-4">
				<NavLink to="/TraineeReports">
					<button className="btn bg-tranparent">
						<ChevronLeft className="big" />
					</button>
				</NavLink>
				Berichtsheft von {instance?.user?.firstname} {instance?.user?.lastname}
				<button className="btn btn-primary btn-sm float-right">PDF Export</button>
			</h2>

			<nav className="text-center mb-5">
				<p>
					<b>Jahr</b>
				</p>
				<div className="btn-group" role="group">
					{years.map((iyear, index) => (
						<button key={index} className={`btn btn-outline-primary${iyear == year ? " active" : ""}`} onClick={() => setYear(iyear)}>{iyear}</button>
					))}
				</div>
			</nav>

			<section className="grid twenty-eighty">
				<aside className="postlist">
					{filteredPosts.map(currentPost => (
						<span key={currentPost.id} className={`a text-secondary ${currentPost?.id == post?.id ? " active" : ""}`} onClick={() => setPost(currentPost)}><span className="badge bg-secondary">KW {DateTime.fromISO(currentPost["published_at"]).weekNumber}</span> {currentPost.title.replace(/KW ?[0-9]+/i, "")}</span>
					))}
				</aside>
				<section>
					<article className="post">
						<header>
							<h1 className="mb-5"><span className="badge bg-primary mr-3">KW {DateTime.fromISO(currentPost?.["published_at"]).weekNumber}</span>{currentPost?.title.replace(/KW *[0-9]+/i, "")}</h1>
						</header>
						{ready ?
							<main dangerouslySetInnerHTML={{__html : currentPost?.html}}></main>
						: <main><Spinner /></main>}
					</article>
				</section>
			</section>
		</>
	);
};

export default InstanceSingle;