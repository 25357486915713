import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";

import {Modal}    from "react-bootstrap";
import {useAlert} from "react-alert";

import {GhostBlog, StringIconComponent} from "utils/StringIcons";
import useForm                          from "components/hooks/useForm";

const EditInstance = ({instance = null, show, setShow, callback}) => {
	const alert                          = useAlert();
	const [users, setUsers]              = useState([]);
	const [formData, handleInput, clear] = useForm({
		name    : "",
		apiKey  : "",
		baseURL : "",
		user    : ""
	});

	useEffect(() => {
		clear(initialState => {
			const newData = instance ? {...instance} : {...initialState};
			newData.user  = newData.user.id || "";

			return newData;
		});
	}, [instance]);

	useEffect(() => {
		axios.get("/users").then(response => {
			setUsers(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Benutzer: ${error}`))
	}, [alert]);

	const save = useCallback(() => {
		const data = {...formData};
		if (instance) {
			axios.put(`/tr-instances/${instance.id}`, {...data}).then(() => {
				alert.success(`Instanz '${instance.name}' wurde erfolgreich gespeichert.`);
				callback();
				setShow(false);
			}).catch(error => alert.error(`Fehler beim Speichern der Instanz: ${error}`));
		} else {
			axios.post("/tr-instances", {...data}).then(() => {
				alert.success("Die neue Instanz wurde erfolgreich angelegt.");
				callback();
				setShow(false);
			}).catch(error => alert.error(`Fehler beim Anlegen der Instanz: ${error}`));
		}
	}, [formData, alert, setShow, instance, callback]);

	return (
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header>
				<Modal.Title>Berichtsheftinstanz <i>(ghost)</i> {instance ? "bearbeiten" : "anlegen"}</Modal.Title>
			</Modal.Header>	
			<Modal.Body>
				<p className="text-center">
					<StringIconComponent icon={GhostBlog} style={{maxWidth : "50px"}} />
				</p>
				<section className="grid form">
					<label>Name</label>
					<input type="text" className="form-control" name="name" onChange={handleInput} value={formData.name} placeholder="Freie Bezeichnung für die Instanz" />
					<label>Basis-URL</label>
					<input type="text" className="form-control" name="baseURL" onChange={handleInput} value={formData.baseURL} placeholder="Basis-URL der Instanz" />
					<label>API Key</label>
					<input type="text" className="form-control" name="apiKey" onChange={handleInput} value={formData.apiKey} placeholder="gültiger API Schlüssel" />
					<label>Eigentümer</label>
					<select className="form-control" name="user" value={formData.user} onChange={handleInput}>
						<option value="" disabled>Besitzer auswählen</option>
						{users.map(user => {
							return <option value={user.id} key={user.id}>{user.firstname} {user.lastname}</option>
						})}
					</select>
				</section>
			</Modal.Body>
			<Modal.Footer>
			<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
			<button className="btn btn-success" onClick={() => save()}>{instance ? "Speichern" : "Anlegen"}</button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditInstance;