import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, NavLink} from "react-router-dom";

import {JournalText} from "react-bootstrap-icons";

import UserContext from "../../components/UserContext";

import InstancesList  from "./components/InstancesList";
import InstanceSingle from "./components/InstanceSingle";

import "./_styles.scss";

const TraineeReports = ({type}) => {
	const {permissions}     = useContext(UserContext);
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (typeof permissions["users-permissions"] !== "undefined")
			setReady(true);
	}, [permissions])

	if (!ready || !permissions.application?.controllers["tr-instances"]?.find.enabled)
		return <></>

	if (type === "nav")
		return (
			<NavLink className={({isActive}) => isActive ? "active" : ""} to="/TraineeReports"><JournalText /> <span>Berichtshefte</span></NavLink>
		);

	return (
		<section className="trainee-reports">
		<nav>
			<ul className="nav nav-tabs">
				<li className="nav-item"><NavLink end className="nav-link" to="/TraineeReports">Instanzen</NavLink></li>
			</ul>
		</nav>
			<Routes>
				<Route index element={<InstancesList />} />
				<Route exact path=":id" element={<InstanceSingle />} />
			</Routes>
		</section>
	);
};

export default TraineeReports;